import { Button, makeStyles, StandardProps, Theme } from "@material-ui/core";
import { StyleClassKey } from "JS/Typescript";
import React, { useState } from "react";
import { Layout } from "../Layout";
import { ActivityType, TrailsFilter } from "JS/Models/General";
import { mapView, getImageSrc, ImageNames } from "JS/Helpers/Media";
import { useOneTrail } from "JS/React/Hooks/Trails";
import { Helmet } from 'react-helmet';
import { TrailsList } from "./TrailsList";
import { TrailsDetailModal } from "./TrailDetailModal";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
} from "react-leaflet";
import { FiltersModal } from "./FiltersModal";
import { useTrails, useGeoPointsIndex } from "JS/React/Hooks/Trails";
import { getMarkerByType } from "JS/Models/General";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 60px)",
    maxHeight: "calc(100vh - 60px)",
    background: theme.navbar.background,
    marginTop: theme.navbar.height,
  },

  accordionContainer: {
    display: "flex",
    padding: theme.spacing(0, 2),
    background: theme.navbar.background,
    alignItems: "center",
    justifyContent: 'center',
    // justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  accordion: {
    width: "50%",
    margin: theme.spacing(4, 0),
    alignSelf: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  buttonSection: {
    // padding: theme.spacing(3, 5),
    display: "flex",
    // justifyContent: "space-around",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },

  trailsAndMapContainer: {
    display: "flex",
    padding: theme.spacing(2, 2),
    overflow: "auto",
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  trailsContainer: {
    paddingRight: theme.spacing(2),
    width: "100%",
    overflow: "auto",
    flex: 0.8,
    [theme.breakpoints.down("md")]: {
      overflow: "unset",
      paddingRight: theme.spacing(0),
      marginTop: theme.spacing(3),
    },
  },
  mapContainer: {
    // flex: 2,
    // minHeight: "50vh",
    // width: "100%",
    height: "100%",
    width: "100%",
  },
  mapContainerWrapper: { flex: 2, width: "100%", minHeight: "50vh" },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
  buttonLeftMargin: {
    marginLeft: theme.spacing(2),
  },
}));


function getSParam(key, searchParams) {
  return searchParams.get(key);
}

function setParam(key, param) {
  const searchParams = new URLSearchParams();
  if (param != null && param != undefined)
    searchParams.append(key, param)
  return searchParams.toString();
}


function setParamByName(key, param) {
  const searchParams = new URLSearchParams();
  if (param != null && param != undefined)
    searchParams.append(key, param)
  return searchParams.toString();
}

function setParams(filter) {
  const searchParams = new URLSearchParams();
  Object.keys(filter).forEach(function (key) {
    if (filter[key] != null && filter[key] != undefined) {
      if (filter[key] == "maxDuration") {
        var num = filter[key] * 60 * 60//dirty fix
        searchParams.append(key, String(num))
      }
      else
        searchParams.append(key, filter[key])
    }
  });
  return searchParams.toString();
}

export type SearchTrailsClassKey = StyleClassKey<typeof useStyles>;

export interface SearchTrailsProps
  extends StandardProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    SearchTrailsClassKey
  > { }

export const SearchTrails = (props) => {

  var modal: SearchTrailsProps = {} as SearchTrailsProps;
  const { location, history } = props;
  const classes = useStyles(modal);
  const { className, ...rest } = modal;
  // const service = new UserLocation();
  // const coord = service.componentDidMount()
  // console.log("erol@@@@@ 123 " + coord[1])

  var parmsFromUrl = {}
  if (location.search.split('?')[1] != undefined) {
    const searchParams = new URLSearchParams(location.search.split('?')[1]);
    parmsFromUrl = {
      activity: getSParam("activity", searchParams),
      country: getSParam("country", searchParams),
      maxDistance: getSParam("maxDistance", searchParams),
      minDistance: getSParam("minDistance", searchParams),
      minDuration: getSParam("minDuration", searchParams),
      maxDuration: getSParam("maxDuration", searchParams),
      keywords: getSParam("keywords", searchParams),
      byDistance: getSParam("byDistance", searchParams) == 0 ? false : true,
      page: getSParam("page", searchParams),
      type: 0,
    }
  }
  else {
    parmsFromUrl = {
      activity: ActivityType.HIKING,
      country: "US",
      maxDistance: null,
      minDistance: null,
      minDuration: 0,
      maxDuration: 180000,
      keywords: "",
      byDistance: 0,
      page: "0",
      type: -1,
    }
  }

  const [filter, setFilter] = useState<TrailsFilter>(
    parmsFromUrl
  );

  const [trailsFilter, setTrailsFilter] = useState<TrailsFilter>(filter);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const { trails, loading, response: trailsRes } = useTrails(trailsFilter);
  const { points: allPoints, boolean: loadingC } = useGeoPointsIndex(trailsFilter.country);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { loading: refetchLoading, response: selectedMapTrail, refetch } = useOneTrail(null);

  return (
    <Layout disableFooter>
      <div className={classes.root}>
        <Helmet>
          <title>{"Search Trails Worldwide | Trail Explorer"}</title>
          <meta
            name="description"
            content="Explore trails from around the world. Filter and search for trails, view GPX tracks on the map, and download trail GPX files. Find your next adventure with Trail Explorer."
          />
          <meta
            name="keywords"
            content="trails, hiking, biking, running, outdoor activities, GPX tracks, trail explorer"
          />
          <meta name="author" content="ALTLAS" />
          <meta name="robots" content="index, follow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          <meta property="og:title" content="Search Trails Worldwide | Trail Explorer" />
          <meta property="og:description" content="Explore trails from around the world. Find your next adventure with Trail Explorer." />
          <meta property="og:image" content="https://altlas-app.com/images/logo.png" />

          <meta property="og:url" content="https://altlas-app.com/search-trails" />
          <meta property="og:type" content="website" />

          <meta name="google-play-app" content="app-id=altitude.alarm.erol.apps" />

        </Helmet>

        <div className={classes.accordionContainer}>
          <Button
            onClick={() => setFilterModalOpen(true)}
            color="primary"
            variant="outlined"
            size="large"
          >
            Filters
          </Button>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=altitude.alarm.erol.apps"
          >
            <img
              alt="download the Android App"
              src={getImageSrc(ImageNames.GOOGLE_PLAY_BADGE)}
              height="80"
            />
          </a>
          <FiltersModal
            filter={filter}
            setFilter={setFilter}
            open={filterModalOpen}
            onClose={() => {
              setFilterModalOpen(false);
            }}
            onSearch={(filter) => {
              filter.type = 0;
              setTrailsFilter(filter);
              var url = setParams(filter);
              history.push(`?${url}`);
            }}
          />


        </div>

        {/* {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )} */}
        {/* {!loading && ( */}
        <div className={classes.trailsAndMapContainer}>
          <div className={classes.trailsContainer}>
            <TrailsList
              trails={trails}
              loading={loading}
              trailsRes={trailsRes}
              filter={trailsFilter}
              setFilter={setTrailsFilter}
              location={location}
              history={history}
            />
          </div>

          <div className={classes.mapContainerWrapper}>
            {!loading && (
              <MapContainer
                center={[0, 0]
                }
                zoom={3}
                className={classes.mapContainer}
              >
                <LayersControl position="topright">
                  <LayersControl.BaseLayer checked name="Street Map">
                    <TileLayer
                      url={mapView.osm.url}
                      {...mapView.osm.default}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite Map">``
                    <TileLayer
                      url={mapView.mapBox.satellite.urlRaster}
                      {...mapView.mapBox.satellite}
                    />
                  </LayersControl.BaseLayer>
                </LayersControl>
                {trails &&
                  trails.length &&
                  trails.map((t) => {
                    return (
                      <Marker position={[t.lat, t.lon]} icon={getMarkerByType(t.actType, true)}
                        eventHandlers={{
                          click: (e) => {
                            if (t.file_name != undefined) {
                              refetch(t.file_name);
                              setDialogOpen(true);
                              var url = setParam("trailInfo", t.urlPath);
                              history.push(`?${url}`);
                            }
                            else {
                              alert("Cannot load this trail")
                            }
                          },
                        }}
                      >
                        <Popup>
                          {t.title}
                        </Popup>
                      </Marker>
                    );
                  })}
                {!loadingC && allPoints &&
                  allPoints.length &&
                  allPoints.map((t) => {
                    return (
                      <Marker
                        position={[t.lat, t.lon]}
                        icon={getMarkerByType(Number(t.actType), false)}
                        eventHandlers={{
                          click: (e) => {
                            if (t.file_name != undefined) {
                              refetch(t.file_name);
                              setDialogOpen(true);
                              var url = setParam("trailInfo", t.file_name);
                              history.push(`?${url}`);
                            }
                          },
                        }}
                      >
                        <Popup>{t.title}</Popup>
                      </Marker>
                    );
                  })}
              </MapContainer>
            )}
          </div>
        </div>
        {/* {trailType  //erol@@
        && (
          <TrailsDetailModal
          open={true}
          onClose={() => setDialogOpen(false)}
          trail={trailType}
        />
        )} */}
      </div>
      {!refetchLoading && selectedMapTrail && dialogOpen
        && (
          <TrailsDetailModal
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            trail={selectedMapTrail}
          />
        )}
    </Layout>
  );
};
