import { config } from "JS/Config";
import { TrailsFilter } from "JS/Models/General";
import { BaseRoutingContext } from "./Context/BaseRoutingContext";

export const routesForContext = () => () => {
  const unauthorizedContext = new BaseRoutingContext();
  const externalUrl = (url: string) => ({ external: true, url });

  return {
    server: {
      root: () => config.baseApiUrl,
      api: ((base: string) => ({
        trails: {
          trailsIndex: (filter: TrailsFilter) => {
            let trailsRoute = `${base}/trails?country=${filter.country}&page=${filter.page}`;
            if (filter.type) {
              trailsRoute = `${trailsRoute}&type=${filter.type}`;
            }
            if (filter.keywords) {
              trailsRoute = `${trailsRoute}&keywords=${filter.keywords}`;
            }
            if (filter.activity) {
              trailsRoute = `${trailsRoute}&activityType=${filter.activity}`;
            }
            if (filter.byDistance) {
              trailsRoute = `${trailsRoute}&byDistance=1`;
              if (filter.maxDistance) {
                trailsRoute = `${trailsRoute}&maxDistance=${filter.maxDistance}`;
              } else {
                trailsRoute = `${trailsRoute}&maxDistance=200`;
              }
              if (filter.minDistance) {
                trailsRoute = `${trailsRoute}&minDistance=${filter.minDistance}`;
              } else {
                trailsRoute = `${trailsRoute}&minDistance=0`;
              }
            } else {
              trailsRoute = `${trailsRoute}&byDistance=0`;
              if (filter.maxDuration) {
                trailsRoute = `${trailsRoute}&maxDuration=${filter.maxDuration * 60 * 60}`;
              } else {
                trailsRoute = `${trailsRoute}&maxDuration=${50 * 60 * 60}`;
              }
              if (filter.minDuration) {
                trailsRoute = `${trailsRoute}&minDuration=${filter.minDuration * 60 * 60}`;
              } else {
                trailsRoute = `${trailsRoute}&minDuration=0}`;
              }
            }
            return trailsRoute;
          },
          geoPoints: (fname: string) => `${base}/geoPoints?fname=${fname}`,
          listCroutes: (code: string) => `${base}/listCroutes?code=${code}`,
          getTrail: (fname: string) => `${base}/trailInfo?fname=${fname}`,
          getTrailByShortId: (shortId: string) => `${base}/trail?shortId=${shortId}`,
        },
      }))(`${config.baseApiUrl}`),
    },
    react: {
      root: () => unauthorizedContext.buildUrl(`/`),
      article: () => unauthorizedContext.buildUrl(`/article`),
      pricing: () => unauthorizedContext.buildUrl(`/pricing`),
      contact: () => unauthorizedContext.buildUrl(`/contact`),
      searchTrails: () => unauthorizedContext.buildUrl(`/search-trails`),
      trails: () => unauthorizedContext.buildUrl(`/trails/:id`),
      support: () => externalUrl(`/support.html`),
      altlasConnect: () => externalUrl("https://connect.altlas-app.com"),
      share: () => unauthorizedContext.buildUrl(`/share`),
      rootUnauthorized: () => unauthorizedContext.buildUrl(`/`),
    },
  };
};

export type Routes = ReturnType<typeof routesForContext>;