import { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore";
import L from 'leaflet';
// import cycling_custom from "/Code/altlas-web/public/images/routesIcons/cycling_custom.svg";
import winter_sport_custom from "../../routesIcons/winter_sport_custom.svg";
import walk_custom from "../../routesIcons/walk_custom.svg";
import trekking_custom from "../../routesIcons/trekking_custom.svg";
import sailing_custom from "../../routesIcons/sailing_custom.svg";
import running_custom from "../../routesIcons/running_custom.svg";
import motorc_custom from "../../routesIcons/motorc_custom.svg";
import hike_custom from "../../routesIcons/hike_custom.svg";
import driving_custom from "../../routesIcons/driving_custom.svg";
import dog_custom from "../../routesIcons/dog_custom.svg";
import climbing_custom from "../../routesIcons/climbing_custom.svg";
import aviation_custom from "../../routesIcons/aviation_custom.svg";
import cycling_custom from "../../routesIcons/cycling_custom.svg";
import mark_marker from "../../routesIcons/mark_marker.svg";





export enum ActivityType {
  HIKING = "0",
  RUNNING = "1",
  CYCLING = "2",
  CLIMBING = "3",
  AVIATION = "4",
  WINTER_SPORTS = "5",
  SAILING = "6",
  DRIVING = "7",
  MOTORCYCLING = "8",
  WALKING = "9",
  DOG_WALKING = "10",
  TREKKING = "11",
}

export interface TrailResponse {
  trail:Trail;
}

export interface TrailsResponse {
  trails: Trail[];
  page: string;
  amount: number;
}
export interface GeoIndexResponse {
  title: string;
  actType: string;
  file_name: string;
  lat: number;
  lon: number;
  // timeMillis:0;
}
export interface TrailsFilter {
  country?: string;
  minDistance?: number;
  maxDistance?: number;
  minDuration?: number;
  maxDuration?: number;
  byDistance?: boolean;
  keywords?: string;
  activity?: ActivityType;
  page?: string;
  lastVisible?: QueryDocumentSnapshot<DocumentData>;
  firstVisible?: QueryDocumentSnapshot<DocumentData>;
  type?:number;
}

export interface ImagePoint {
  description: string;
  lon: number;
  lat: number;
  imgName: string;
  actType: number;
  color: string
}

export interface Trail {
  actType: number;
  alt: string;
  author: string;
  country_code?: string;
  country: string;
  city: string;
  diffSec: number;
  dist: string;
  duration: string;
  elev: string;
  elevationM: number;
  file_name: string;
  gDistanceMetric: number;
  id: string;
  isRoute: number;
  lat: number;
  latLon: string;
  lon: number;
  title: string;
  tripTime: string;
  version?: string;
  scalars?: number[];
  description?: string;
  geoPoints?: number[];
  imagePoints? : ImagePoint[];
  urlPath?: string;
}

export var cMarker = new L.Icon({
  iconUrl: hike_custom,
  iconRetinaUrl: hike_custom,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(45,45,true),
  className: 'leaflet-div-icon'
});

export const getMarkerByType = (actType: number, mark: boolean) : L.Icon => {

  var tempMarker = new L.Icon({
    iconUrl: getIconsByActivityType(String(actType)),
    iconRetinaUrl: getIconsByActivityType(String(actType)),
    iconAnchor: null,
    popupAnchor: [0, 0],
    shadowUrl: mark? mark_marker:null,
    shadowSize: mark? [50, 50]:null,
    shadowAnchor: null,
    iconSize: new L.Point(40,40,true),
    className: 'dummy'
  });

  return tempMarker

};

export const getIconsByActivityType = (actType: string): string => {
  switch (actType) {
    case ActivityType.HIKING:
      return hike_custom;
    case ActivityType.RUNNING:
      return running_custom;
    case ActivityType.CYCLING:
      return cycling_custom;
    case ActivityType.CLIMBING:
      return climbing_custom;
    case ActivityType.AVIATION:
      return aviation_custom;
    case ActivityType.WINTER_SPORTS:
      return winter_sport_custom;
    case ActivityType.SAILING:
      return sailing_custom;
    case ActivityType.DRIVING:
      return driving_custom;
    case ActivityType.MOTORCYCLING:
      return motorc_custom;
    case ActivityType.WALKING:
      return walk_custom;
    case ActivityType.DOG_WALKING:
      return dog_custom;
    case ActivityType.TREKKING:
      return trekking_custom;
    default:
      return hike_custom;
  }
};

export const greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});


export const poiIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const poiIconBlue = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});