import {
  Trail,
  TrailsFilter,
  TrailsResponse,
  GeoIndexResponse,
} from "JS/Models/General";
import { TrailsService } from "JS/Services/Trails/Service";
import { useCallback, useEffect, useState } from "react";


const service = new TrailsService();



export const useOneTrail = (identifier: string = null) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Trail>(null);

  const refetch = useCallback((identifier: string) => {
    setLoading(true);

    // Check if identifier is a shortId (9 characters of hex)
    const hasTrails = identifier.includes('-trails');
    const endsWithNineChars = /[a-zA-Z0-9]{9}$/.test(identifier);

    const apiCall = hasTrails && endsWithNineChars 
      ? service.getTrailByShortId(identifier)
      : service.getTrailByName(identifier);

    return apiCall
      .then((val) => {
        setResponse(val.trail);
        return val.trail;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (identifier !== null) {
      refetch(identifier);
    }
  }, [identifier]);

  return {
    refetch,
    response,
    loading,
  };
};

export const useGeoPoints = (cCode: string = null) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<number[]>(null);

  const refetch = useCallback((fname: string) => {
    setLoading(true);

    return service
      .getGeoPoints(fname)
      .then((val) => {
        setResponse(val);
        return val;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (cCode !== null) {
      refetch(cCode);
    }
  }, [cCode]);

  return {
    refetch,
    points: response,
    loading,
  };
};

export const useTrails = (filter: TrailsFilter = null) => {
  const [loading, setLoading] = useState(false);
  const [trails, setTrails] = useState<Trail[]>(null);
  const [response, setResponse] = useState<TrailsResponse>(null);

  const refetch = useCallback((filter: TrailsFilter) => {
    setLoading(true);

    return service
      .getTrailsByFilter(filter)
      .then((val) => {
        setResponse(val);
        setTrails(val.trails);
        return val;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filter !== null) {
      refetch(filter);
    }
  }, [filter]);

  return {
    refetch,
    trails,
    response,
    loading,
  };
};

export const useGeoPointsIndex = (code : string = null) => {
  const [loadingC, setLoading] = useState(false);
  const [allPoints, setTrails] = useState<GeoIndexResponse[]>(null);
  const [response, setResponse] = useState<GeoIndexResponse[]>(null);

  const refetch = useCallback((code : string) => {
    setLoading(true);

    return service
      .geoPointsIndex(code)
      .then((val) => {
        setResponse(val);
        setTrails(val);
        return val;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (code !== null) {
      refetch(code);
    }
  }, [code]);

  return {
    refetch,
    allPoints,
    points: response,
    boolean: loadingC,
  };
};
