import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import clsx from "clsx";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  StandardProps,
  Theme,
  Typography,
} from "@material-ui/core";
import { StyleClassKey } from "JS/Typescript";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { ActivityType, Trail } from "JS/Models/General";
import { XCircle, Download } from "lucide-react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { getName } from "country-list";
import 'react-medium-image-zoom/dist/styles.css'

import {
  activityTypeToString,
  getCordinates,
  getIconsByActivityType,
  getTrailImageSrc,
  getImagePoint,
  normalizeValue,
  normalizeValueNumber,
} from "JS/Helpers/Helpers";
import {
  LayersControl,
  MapContainer,
  Polyline,
  TileLayer,
  Marker,
  Popup
} from "react-leaflet";

import { greenIcon, redIcon, poiIcon, poiIconBlue } from "JS/Models/General";
import { getImageSrc, ImageNames, mapView } from "JS/Helpers/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGeoPoints } from "JS/React/Hooks/Trails";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fontWeightBolder: {
    fontWeight: 600,
  },
  actTypeIcon: {
    color: theme.palette.primary.main,
    fontSize: "4rem",
  },
  mapContainer: {
    minHeight: "60vh",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  authorContainer: {
    background: theme.palette.grey[100],
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  marginTopBottom: {
    margin: theme.spacing(2, 0),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  elementsGridContainer: {
    padding: theme.spacing(3),
    margin: 0,
    textAlign: "center",
  },
  elementValue: {
    fontWeight: 600,
  },
  elementContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  nameImage: {
    height: 200,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
      width: "100%",
    },
  },
  galleryImageStyle: {
    width: "100%",
    height: 300,
    "& .image-gallery-slide img": {
      objectFit: "cover",
      borderRadius: theme.shape.borderRadius,
    }
  },
  downloadButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    fontSize: "1rem",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  badgeContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  }
}));

export type TrailsDetailModalClassKey = StyleClassKey<typeof useStyles>;

export interface TrailsDetailModalProps
  extends StandardProps<{}, TrailsDetailModalClassKey> {
  open: boolean;
  onClose: () => void;
  trail: Trail;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const TrailsDetailModal = (props: TrailsDetailModalProps) => {
  const classes = useStyles(props);
  const { open, onClose, trail } = props;
  const { points } = useGeoPoints(trail.file_name);
  const [srcErr, setSrcErr] = useState(false);

  const handleDownloadClick = (url) => {
    window.open(url, '_blank');
  };

  const handleDownloadGpx = async (routeLink) => {
    const serverUrl = `https://altimeter-alarm-b4797865fdaf.herokuapp.com/get-gpx?fname=${routeLink}`

    try {
      const response = await fetch(serverUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Server responded with an error: ${response.statusText}`);
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      
      let filename = 'default_filename.gpx';
      const filenameRegex = /filename="([^"]+)"/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
        filename = matches[1];
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Failed to download the file:', error);
    }
  };

  const wazeLink = points ? 
    `https://www.waze.com/livemap?lat=${points[0]}&lon=${points[1]}&zoom=20` :
    `https://www.waze.com/livemap?lat=${trail.lat}&lon=${trail.lon}&zoom=20`

  const RouteLink = `https://altlas-app.com/view-trail?id=${trail.file_name}`;

  var images = []
  const hasmain = (trail.imagePoints === undefined || trail.imagePoints && trail.imagePoints[0].imgName.includes("_1"))
  
  if (trail.imagePoints) {
    images = trail.imagePoints.map((imgPoint, index) => ({
      original: getImagePoint(imgPoint.imgName),
      thumbnail: getImagePoint(imgPoint.imgName),
      description: imgPoint.description,
    }));
  }
  
  if (hasmain) {
    images.push({
      original: getTrailImageSrc(trail.file_name),
      thumbnail: getTrailImageSrc(trail.file_name),
      description: "ALTLAS APP",
    });
  }

  return (
    <>
  <Helmet>
    {/* Keep your existing tags */}
    
    {/* Add OpenGraph tags */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content={`ALTLAS | ${trail.title} - Trail Information`} />
    <meta property="og:description" content={`Explore ${trail.title} ${activityTypeToString(`${trail.actType}` as ActivityType)} in ${trail.city || ''}, ${getName(trail.country_code) || ''}. Distance: ${trail.gDistanceMetric}km`} />
    <meta property="og:url" content={`https://altlas-app.com/${trail.urlPath}`} />
    <meta property="og:image" content={getTrailImageSrc(trail.file_name)} />

    {/* Add Twitter Card tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={`ALTLAS | ${trail.title}`} />
    <meta name="twitter:description" content={`${trail.description || `Explore this ${activityTypeToString(`${trail.actType}` as ActivityType)} trail`}`} />
    <meta name="twitter:image" content={getTrailImageSrc(trail.file_name)} />
  </Helmet>

      <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h5" className={classes.fontWeightBolder}>{trail.title}</Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <XCircle size={24} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={4}>
              <Grid item md={2} />
              <Grid item md={5} xs={12}>
                <div className={clsx(classes.authorContainer, classes.marginBottom)}>
                  <div>
                    <FontAwesomeIcon
                      className={classes.actTypeIcon}
                      icon={getIconsByActivityType(`${trail.actType}` as ActivityType)}
                    />
                  </div>
                  <div>
                    <div className={classes.marginBottom}>
                      <Typography className={classes.fontWeightBolder} color="textPrimary" variant="h6">
                        {activityTypeToString(`${trail.actType}` as ActivityType)}
                      </Typography>
                      <Typography color="textPrimary" variant="body1">
                        {normalizeValue(trail.title)}
                      </Typography>
                    </div>

                    <div className={classes.marginBottom}>
                      <Typography className={classes.fontWeightBolder} color="textPrimary" variant="subtitle1">
                        Author
                      </Typography>
                      <Typography color="textPrimary" variant="body1">
                        {trail.author ? normalizeValue(trail.author) : "ALTLAS"}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.fontWeightBolder} color="textPrimary" variant="subtitle1">
                        Trip Time
                      </Typography>
                      <Typography color="textPrimary" variant="body1">
                        {normalizeValue(trail.tripTime)}
                      </Typography>
                    </div>
                  </div>
                </div>

                <MapContainer center={[trail.lat, trail.lon]} zoom={15} className={classes.mapContainer}>
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street Map">
                      <TileLayer url={mapView.osm.url} {...mapView.osm.default} />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite Map">
                      <TileLayer url={mapView.mapBox.satellite.urlRaster} {...mapView.mapBox.satellite} />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                  {points && (
                    <Polyline pathOptions={{ color: "blue" }} positions={getCordinates(points)} />
                  )}
                  {points && trail && (
                    <Marker position={[points[0], points[1]]} icon={greenIcon}>
                      <Popup>Start</Popup>
                    </Marker>
                  )}
                  {points && trail && (
                    <Marker position={[trail.lat, trail.lon]} icon={redIcon}>
                      <Popup>Finish</Popup>
                    </Marker>
                  )}
                  {trail.imagePoints && trail.imagePoints.map((point, index) => (
                    <Marker
                      key={index}
                      position={[point.lat, point.lon]}
                      icon={point.color == "black" ? poiIconBlue : poiIcon}
                    >
                      <Popup>{point.description}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </Grid>

              <Grid container item md={3} xs={12}>
                <Grid item xs={6} className={classes.elementsGridContainer}>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">Country</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {trail.country_code ? normalizeValue(getName(trail.country_code)) : "Unknown"}
                    </Typography>
                  </div>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">City</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {trail.city ? normalizeValue(trail.city) : "ALTLAS"}
                    </Typography>
                  </div>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">Distance</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {normalizeValueNumber(trail.gDistanceMetric)}km
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={6} className={classes.elementsGridContainer}>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">Altitude</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {normalizeValue(trail.alt)}
                    </Typography>
                  </div>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">Elevation</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {normalizeValue(trail.elev)}
                    </Typography>
                  </div>
                  <div className={classes.elementContainer}>
                    <Typography variant="subtitle1">Duration</Typography>
                    <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                      {normalizeValue(trail.duration)}
                    </Typography>
                  </div>
                  {trail.description && (
                    <div className={classes.elementContainer}>
                      <Typography className={classes.elementValue} color="textPrimary" variant="body1">
                        {normalizeValue(trail.description)}
                      </Typography>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.elementsGridContainer}>
                  <div className={classes.elementContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <IconButton
                        className={classes.downloadButton}
                        onClick={() => handleDownloadGpx(trail.file_name)}
                        aria-label="Download GPX"
                      >
                        <Download size={24} />
                        <Typography variant="button">Download GPX</Typography>
                      </IconButton>

                      <IconButton
                        className={classes.downloadButton}
                        onClick={() => handleDownloadClick(RouteLink)}
                        aria-label="Show in the app"
                      >
                        <FileDownloadIcon />
                        <Typography variant="button">Show in the app</Typography>
                      </IconButton>
                      
                      <div className={classes.badgeContainer}>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=altitude.alarm.erol.apps"
                        >
                          <img
                            alt="download the Android App"
                            src={getImageSrc(ImageNames.GOOGLE_PLAY_BADGE)}
                            height="60"
                          />
                        </a>
                        <a
                          target="_blank"
                          href={wazeLink}
                        >
                          <img
                            src={getImageSrc(ImageNames.WAZE_BADGE)}
                            height="60"
                            alt="Navigate with Waze"
                          />
                        </a>
                      </div>
                    </div>

                    {images.length > 0 && (
                      <>
                      
                        <Typography variant="h6" gutterBottom align="center">
                          Trail Photos
                        </Typography>
                        <ImageGallery 
                          items={images}
                          additionalClass={classes.galleryImageStyle}
                          autoPlay={true}
                          showBullets={true}
                          onErrorImageURL={getImageSrc(ImageNames.DUMMY_IMAGE)}
                          onError={() => setSrcErr(true)}
                        />
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item md={2} />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default TrailsDetailModal;