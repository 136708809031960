import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useRouting } from "../Hooks/Routes";
import { LandingPage } from "./LandingPage/LandingPage";
import { SearchTrails } from "./SearchTrails/SearchTrails";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from 'react-router-dom';
import { useOneTrail } from "JS/React/Hooks/Trails";
import { TrailsDetailModal } from "./SearchTrails/TrailDetailModal";

import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faBinoculars,
  faCompactDisc,
  faSearchLocation,
  faLayerGroup,
  faMapMarkedAlt,
  faMountain,
  faTachometerAlt,
  faLongArrowAltUp,
  faShareAlt,
  faUsers,
  faCog,
  faComments,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faHiking,
  faRunning,
  faBiking,
  faSkating,
  faShip,
  faCar,
  faMotorcycle,
  faWalking,
  faDog,
} from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";
import {
  faGooglePlay,
  faFacebookF,
  faTwitter,
  faPinterestP,
  faInstagram,
  faTelegram,
  faFly,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faBars,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faBinoculars,
  faCompactDisc,
  faSearchLocation,
  faLayerGroup,
  faMapMarkedAlt,
  faMountain,
  faTachometerAlt,
  faLongArrowAltUp,
  faCompass,
  faShareAlt,
  faUsers,
  faCog,
  faComments,
  faGooglePlay,
  faFacebookF,
  faTelegram,
  faTwitter,
  faPinterestP,
  faInstagram,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faHiking,
  faRunning,
  faBiking,
  faSkating,
  faShip,
  faCar,
  faMotorcycle,
  faWalking,
  faDog,
  faFly
);

// Utility function to parse trail identifier from URL
const parseTrailIdentifier = (pathname: string): string | null => {
  // If pathname is empty or undefined, return null
  if (!pathname) return null;
  // Check if it's a new format URL (hiking-trails/title-shortid)

  const newFormatMatch = pathname.match(/\/(default|hiking|cycling|running|biking|climbing|aviation|winter-sports|sailing|driving|motorcycling|walking|dog-walking|trekking)-trails\/(.+)-([a-f0-9]{9})/i);
  if (newFormatMatch) {
    return newFormatMatch[0].substring(1);;
  }

  // Check if it's old format URL (/trails/filename)
  const oldFormatMatch = pathname.split("/trails/")[1];
  if (oldFormatMatch) {
    return oldFormatMatch.replaceAll('+', ' ');
  }

  return null;
};

const Root = () => {
  const { routeBuilder } = useRouting();
  const routeProvider = routeBuilder();
  const location = useLocation();
  const [, setDialogOpen] = useState<boolean>(false);

  // Get trail identifier using the utility function
  const trailIdentifier = parseTrailIdentifier(location.pathname);
  const { loading: urlTrailLoading, response: trailRes } = useOneTrail(trailIdentifier || "");

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact={true}
          path={routeProvider.react.root()}
          render={() => <LandingPage />}
        />
        
        <Route
          exact={true}
          path={routeProvider.react.searchTrails()}
          render={({ location, history }) => (
            <SearchTrails location={location} history={history} />
          )}
        />
        
        {/* Support both old and new URL formats */}
        <Route
          exact={true}
          path={[
            routeProvider.react.trails(),           // Old format: /trails/:filename
            "/:activity-trails/:titleAndId"         // New format: /hiking-trails/title-shortid
          ]}
          render={() => (
            !urlTrailLoading && trailRes && (
              <TrailsDetailModal 
                open={trailRes != null}
                onClose={() => setDialogOpen(true)}
                trail={trailRes}
              />
            )
          )}
        />

        <Route
          exact={true}
          path={routeProvider.react.share()}
          render={() => <LandingPage />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Root;
