import React from "react";
import {
  IconButton,
  Paper,
  PaperProps,
  StandardProps,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StyleClassKey } from "../../Typescript";
import { useRouting } from "../Hooks/Routes";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getImageSrc, ImageNames } from "JS/Helpers/Media";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.navbar.height,
    background: theme.navbar.background,
    paddingLeft: "160px",
    paddingRight: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
    zIndex: 20,
    top: 0,
    // marginBottom: theme.spacing(6),
    [theme.breakpoints.down(theme.navbar.breakpoint)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  navItems: {
    display: "flex",
    [theme.breakpoints.down(theme.navbar.breakpoint)]: {
      display: "none",
    },
  },
  navMenu: {
    margin: theme.spacing(0, 2.5),
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  barsIcon: {
    color: theme.palette.common.black,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  barsWrapper: {
    display: "none",
    [theme.breakpoints.down(theme.navbar.breakpoint)]: {
      display: "inherit",
    },
  },
}));

export type AppNavbarClassKey = StyleClassKey<typeof useStyles>;

export interface AppNavbarProps
  extends StandardProps<PaperProps, AppNavbarClassKey> {}

interface NavItem {
  title: string;
  route: string;
  hasChildren?: boolean;
  isChild?: boolean;
  item?: NavItem[];
}

export const AppNavbar = (props: AppNavbarProps) => {
  const classes = useStyles(props);
  const { className, ...rest } = props;

  const { routeBuilder } = useRouting();
  const routeProvider = routeBuilder();

  const history = useHistory();

  const navItems: NavItem[] = [
    {
      title: "Home",
      route: routeProvider.react.root(),
    },
    // {
    //   title: "Article",
    //   route: routeProvider.react.article(),
    // },
    // {
    //   title: "Pricing",
    //   route: routeProvider.react.pricing(),
    // },
    // {
    //   title: "Contact",
    //   route: routeProvider.react.contact(),
    // },
    {
      title: "Search Trails",
      route: routeProvider.react.searchTrails(),
    },
    {
      title: "Plan & Sync Trails",
      route: routeProvider.react.altlasConnect(),
    },
    {
      title: "Support & FAQ",
      route: routeProvider.react.support(),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpened = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClosed = (item: NavItem) => {
    history.push(item.route);
    setAnchorEl(null);
  };

  const handleNavItemClick = (route: string | { external: true; url: string }) => {
    if (typeof route === 'string') {
      history.push(route);
    } else if (route.external) {
      window.location.href = route.url;
    }
  };

  return (
    <Paper elevation={2} square className={classes.header} {...rest}>
      {/* <Typography
        variant="h5"
        style={{
          fontWeight: "bolder",
        }}
      >
        ALTLAS
      </Typography> */}
      <img
        style={{
          cursor: "pointer",
        }}
        width={50}
        src={getImageSrc(ImageNames.ALTLAS_LOGO)}
        onClick={() => history.push(routeProvider.react.root())}
      />

      <div className={classes.navItems}>
        {navItems.map((item) => (
          <Typography
            key={item.title}
            className={classes.navMenu}
            variant="body1"
            onClick={() => handleNavItemClick(item.route)}
          >
            {item.title}
          </Typography>
        ))}
      </div>

      <div className={classes.barsWrapper}>
        <IconButton
          style={{
            padding: "0px",
          }}
          onClick={handleMenuOpened}
        >
          <FontAwesomeIcon
            icon={["fas", "bars"]}
            className={classes.barsIcon}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClosed}
        >
          {navItems.map((item: NavItem) => (
            <MenuItem key={item.title} onClick={() => handleMenuClosed(item)}>
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Paper>
  );
};
