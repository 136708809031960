import {
  GeoIndexResponse,
  TrailsFilter,
  TrailsResponse,
  TrailResponse
} from "JS/Models/General";
import axios from "axios";
import { routesForContext } from "JS/React/Routing";

export class TrailsService {
  protected routes = routesForContext()();
  protected lastVisible = null;

  async getTrailByShortId(shortId: string): Promise<TrailResponse> {
    const response = axios
      .get(this.routes.server.api.trails.getTrailByShortId(shortId))
      .then((x) => x.data);
    return response;
  }

  async getTrailByName(fname: string): Promise<TrailResponse> {
    if(fname)
      fname = fname.replaceAll('+', '%2b')//ugly fix
    const response = axios
      .get(this.routes.server.api.trails.getTrail(fname))
      .then((x) => x.data);
    return response;
  }

  async getTrailsByFilter(filter: TrailsFilter): Promise<TrailsResponse> {
    if(filter.page)
      filter.page = filter.page.replaceAll('+', '%2b')//ugly fix
    const response = axios
      .get(this.routes.server.api.trails.trailsIndex(filter))
      .then((x) => x.data);
    return response;
  }

  async geoPointsIndex(fname : string): Promise<GeoIndexResponse[]> {
    const response = axios
      .get(this.routes.server.api.trails.listCroutes(fname))
      .then((x) => x.data);
    return response;
  }

  async getGeoPoints(fname: string): Promise<number[]> {
    if(fname)
      fname = fname.replaceAll('+', '%2b')//ugly fix
    const response = axios
      .get(this.routes.server.api.trails.geoPoints(fname))
      .then((x) => x.data);

    return response;
  }
}
