import {
  CircularProgress,
  makeStyles,
  StandardProps,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { StyleClassKey } from "JS/Typescript";
import React, { useState } from "react";
import { Trail, TrailsFilter, TrailsResponse } from "JS/Models/General";
import { SingleTrail } from "./SingleTrail";
import { AppRoundedButton } from "JS/React/Components/AppRoundedButton";
import { TrailsDetailModal } from "./TrailDetailModal";
import { useOneTrail } from "JS/React/Hooks/Trails";
import { RouteComponentProps } from 'react-router-dom';



const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 0,
  },
  bolderText: {
    fontWeight: "bold",
  },
  pageButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 2),
  },
  pageButton: {
    textAlign: "center",
    margin: theme.spacing(2, 0),
  },
}));

export type TrailsClassKey = StyleClassKey<typeof useStyles>;

export interface TrailsProps
  extends StandardProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    TrailsClassKey
  > {
  filter: TrailsFilter;
  setFilter: (filter: TrailsFilter) => void;
  trails: Trail[];
  loading: boolean;
  trailsRes: TrailsResponse;
  location:Location;
  history:RouteComponentProps["history"];
}

function getParam(key, location) {
  const searchParams = new URLSearchParams(location.search);
  return (searchParams.get(key) || '')
}

function setParam(key, param) {
  const searchParams = new URLSearchParams();
    if (param != null && param !== undefined)
      searchParams.append(key, param)
  return searchParams.toString();
}


export const TrailsList = (props: TrailsProps) => {
  
  const classes = useStyles(props);
  const { filter, setFilter, className, trails, loading, trailsRes, location, history } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [forceOpen, setForceDialogOpen] = useState<boolean>(true);
  const [fromUrl, setFromUrl] = useState<boolean>(true);

  const [selectedTrail, setSelectedTrail] = useState<Trail>(null);
  const trailInfo = getParam("trailInfo", location)
  const { loading: urlTrailLoading, response: trailRes} = useOneTrail(trailInfo);

  var erol: Trail = {} as Trail;
  var trailUrl = false

  if(trailRes!= null && fromUrl){
    erol = trailRes
    trailUrl = true
  }

  return (
    <div className={clsx(className, classes.root)}>
      {dialogOpen && (
        <TrailsDetailModal
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          trail={selectedTrail}
        />
      )}
      {trailUrl && forceOpen && !urlTrailLoading && !dialogOpen && selectedTrail == null && (//ulr is not working now...
        <TrailsDetailModal
        open={trailUrl}
        onClose={() =>
          {
            history.push(`${history.location.pathname}`)
            setForceDialogOpen(false)
          }
        }
        trail={erol}
        />
      )}
      {!loading && trails && (
        <Typography
          className={classes.bolderText}
          variant="h6"
        >{`Showing the first ${trails.length} trails`}</Typography>
      )}
      <div
        style={{
          textAlign: "center",
          height: "100%",
          overflow: "auto",
          minHeight: "100px",
        }}
      >
        {loading && <CircularProgress />}
        {!loading &&
          trails &&
          trails.length &&
          trails.map((trail, idx) => (
            <SingleTrail
              key={idx}
              trail={trail}
              onChoose={(trail: Trail) => {
                setSelectedTrail(trail);
                setDialogOpen(true);
                setFromUrl(false)
                var url = setParam("trailInfo", trail.urlPath);
                history.push(`?${url}`);
              }}
            />
          ))}
        {!loading && !trails && (
          <Typography variant="body1">No Trails to show</Typography>
        )}
      </div>
      <div className={classes.pageButtonWrapper}>
        {/* <AppRoundedButton
          buttonVariant="blue"
          className={classes.pageButton}
          onClick={() =>
            setFilter({
              ...filter,
              page: "prev",
              firstVisible: firstVisible,
            })
          }
        >
          Previous
        </AppRoundedButton> */}
        {trails && (
        <AppRoundedButton
          buttonVariant="blue"
          className={classes.pageButton}
          onClick={() =>
            setFilter({
              ...filter,
              page: trailsRes.page,
            })
          }
        >
          Show more results
        </AppRoundedButton>
        )}
      </div>
    </div>
  );
};


